import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ServicesLink from '../pages/ServicesLink.js'

function Footer(props) {
//   console.log(props);
    return (
        <React.Fragment>
            <div className='FooterStyle'>
                <Container>
                    <Row>
                        <Col lg="2" className="mb-3">
                            <h3>Navigation</h3>
                            <ul>
                                {/* <li><Link to="/" onClick={props.scrollToTop}>Home</Link></li> */}
                                <li><Link to="about-us" onClick={props.scrollToTop}>About us</Link></li>
                                <li><Link to="portfolio" onClick={props.scrollToTop}>Portfolio</Link></li>
                                <li><Link to="career" onClick={props.scrollToTop}>Career</Link></li>
                                <li><Link to="school-erp" onClick={props.scrollToTop}>School ERP / CIM</Link></li>
                                <li><Link to="privacy-policy" onClick={props.scrollToTop}>Privacy Policy</Link></li>
                                <li><Link to="term-and-conditions" onClick={props.scrollToTop}>Term & Conditions</Link></li>
                                <li><Link to="return-policy" onClick={props.scrollToTop}>Return Policy</Link></li>
                                <li><Link to="contact-us" onClick={props.scrollToTop}>Contact Us</Link></li>
                            </ul>
                        </Col>
                        <Col lg="3" className="mb-3">
                            <h3>Services</h3>
                            <ServicesLink scrollToTop={props.scrollToTop} />
                        </Col>
                        <Col lg="3" className="mb-3">
                            <h3>Contact Us</h3>
                            <ul>
                                <li><i className='icon-envelope-open'></i> {props.websettings.store_email_info}</li>
                                <li><i className='icon-headphones'></i> {props.websettings.store_phone}</li>
                                <li><i className='icon-phone-incoming'></i> {props.websettings.store_mobile}</li>
                                {/* <li><a href={`https://wa.me/${props.settings.store_whatsapp_no}?text=Hello! TND Soft`} target="_blank" rel="noreferrer"> <span className="material-icons whatsappIcon">whatsapp</span>  {props.settings.store_whatsapp_no}</a></li> */}
                                <li><i className='icon-map2'></i>  {props.websettings.store_address}</li>
                            </ul>
                        </Col>
                        <Col lg="4" className="mb-3">
                            <h3>Subscribe to our newsletter.</h3>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Text className="text-white">
                                        Register to our newsletter and get special deals you will not find anyplace else directly to your mailbox!
                                    </Form.Text>
                                    <Form.Control type="email" className="mt-3" placeholder="Enter Your Email" />
                                </Form.Group>
                                <Button variant="primary" type="submit">Subscribe</Button>
                            </Form>
                            {/* <ul>
                                <li><i className='icon-envelope-open'></i> info@tndsoft.in</li>
                                <li><i className='icon-headphones'></i> +91 9582156430</li>
                                <li><i className='icon-phone-incoming'></i> +91 6390909222</li>
                            </ul> */}
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <div className="footerb">Copyright TND Soft @ 2023. All right reserved</div>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default Footer;