import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Card, Modal } from 'react-bootstrap';
import Spinner from './SpinnerLoading.js';
import Globals from '../common/Globals.js';
import ErrorPage from './ErrorPage.js';
export default function CareerPage(props) {
  const [showmodal, setShowmodal] = useState(false);
  const [showError, setpShowError] = useState(false);
  const handleClose = () => setShowmodal(false);
  // const handleShow = () => setShowmodal(true);
  // modal end
  const [loading, setLoading] = useState(true);
  const [pagename, setPagename] = useState("");
  // const [content, setContent] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [metatags, setMetatags] = useState("");
  const [metaTitle, setMetaTitle] = useState("");

  useEffect(() => {
    getData()
  }, [])
  const getData = async () => {
    await axios.get(Globals.BASE_URL + '' + Globals.CONTENT_PAGE_URL + '/15', {}, {})
    .then(function(response){
      if (response.request.status === 200) {
        var count = Object.keys(response.data).length;
        if (count) {
          setPagename(response.data.pagename);
          setMetaTitle(response.data.meta_title);
          setMetatags(response.data.meta_tags);
          setMetadescription(response.data.meta_description);
          setLoading(false)
        } else {
          setpShowError(true);
        }
      } else {
        setpShowError(true);
      }
    })
  }
  // function pagedesc() {
  //   return { __html: content };
  // }
  // title
  document.title = metaTitle;
  document.querySelector("meta[name='description']").setAttribute('content', metadescription);
  document.querySelector("meta[name='keywords']").setAttribute('content', metatags);
  // console.log(pagename)
  // title end
  return (
    <React.Fragment>
      {
        showError ? <ErrorPage /> :
      loading ? (
        <div className='speen text-center'><Spinner /></div>
      ) : (
        <div className='pageStyle'>
          <div className='pageBanner'>
            <Container>
              <h1>{pagename}</h1>
            </Container>
          </div>
          <div className='pageBody'>
            <Container>
              <div className="ServicesStyle">
                <Row>
                  <Col md="12">
                    {/* <div dangerouslySetInnerHTML={pagedesc()} ></div> */}
                    <Row>
                      <Col md="6" className='mb-3 '>
                        <Card className='shadow h-100'>
                          <Card.Header>1. Position - <strong>PHP - Developer</strong></Card.Header>
                          <Card.Body>
                            <ul>
                              <li>Key Skill - Core PHP, My SQL, JQuery, Ajax</li>
                              <li>Experience - 3+ Year</li>
                              <li>Work Location - Varanasi</li>
                            </ul>
                            {/* <Button variant="primary" onClick={handleShow} size="sm">Apply Online</Button> */}
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col md="6" className='mb-3 '>
                        <Card className='shadow h-100' >
                          <Card.Header>2. Position - <strong>DOT - Net - Developer</strong></Card.Header>
                          <Card.Body>
                            <ul>
                              <li>Key Skill - C#, SQL Server</li>
                              <li>Experience - 3+ Year</li>
                              <li>Work Location - Varanasi</li>
                            </ul>
                            {/* <Button variant="primary" size="sm">Apply Online</Button> */}
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" className='mb-3'>
                        <Card className='shadow h-100'>
                          <Card.Header>3. Position - <strong> Sales Manager</strong></Card.Header>
                          <Card.Body>
                            <ul>
                              <li>Qualification - BBA, MBA</li>
                              <li>Experience - 1+ Year</li>
                              <li>Work Location - Varanasi</li>
                            </ul>
                            {/* <Button variant="primary" size="sm">Apply Online</Button> */}
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" className='mb-3 '>
                        <Card className='h-100 shadow'>
                          <Card.Header>4. Position - <strong>SEO</strong></Card.Header>
                          <Card.Body>
                            <ul>
                              <li>Key Skill - Technical SEO, Content Writing, Link Building, Social Media Marketing, Knowledge of HTML</li>
                              <li>Experience - 1+ Year</li>
                              <li>Work Location - Varanasi</li>
                            </ul>
                            {/* <Button variant="primary" size="sm">Apply Online</Button> */}
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md="6" className='mb-3 '>
                        <Card className='h-100 shadow' >
                          <Card.Header>5. Position - <strong>Web Designer</strong></Card.Header>
                          <Card.Body>
                            <ul>
                              <li>Key Skill - HTML, JavaScript,</li>
                              <li>Dreamweaver, PhotoShop, Flash</li>
                              <li>Experience - 3+ Year</li>
                              <li>Work Location - Varanasi</li>
                            </ul>
                            {/* <Button variant="primary" size="sm">Apply Online</Button> */}
                          </Card.Body>
                        </Card>
                      </Col>

                    </Row>
                  </Col>
                  <Modal
                    show={showmodal}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Post Your Resume</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control type="text" placeholder="Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          {/* <Form.Label></Form.Label> */}
                          <Form.Control type="email" placeholder="Email address" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          {/* <Form.Label></Form.Label> */}
                          <Form.Control type="text" placeholder="Mobile No." />
                        </Form.Group>
                        <Form.Select className='mb-3'>
                          <option> For the Post of</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                        <Row>
                          <Col md="6">
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Resume Upload</Form.Label>
                              <Form.Control type="file" placeholder="" />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Upload Photo</Form.Label>
                              <Form.Control type="file" placeholder="" />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </Form>
                    </Modal.Body>
                  </Modal>
                  <Col md="5 d-none">
                    <Card className="bg-dark text-white">
                      <Card.Body>

                        {/* <Form.Group className="mb-3">
                            <Form.Label>Select Job</Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Select</option>
                              <option value="1">Web Design</option>
                              <option value="1">UI Developer </option>
                              <option value="1">Web Development</option>
                              <option value="2">Digital Marketing</option>
                              <option value="3">Social Media Optimization</option>
                              <option value="3">DOT - Net - Developer</option>
                              <option value="3">Sales Manager</option>
                            </Form.Select>
                          </Form.Group> */}

                      </Card.Body>
                    </Card>

                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}