import { Container } from 'react-bootstrap';
import React from 'react';
// import { Link } from 'react-router-dom';
import About from './home/AboutHome.js'
import OurMission from './home/OurMission.js'
import ServicesHome from './home/ServicesHome.js'
import HomeBanner from './home/HomeBanner.js'
import Clints from './Clints.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrowone from '../assets/images/rv1.png';
import arrowtwo from '../assets/images/rv2.png';
import Workflow from './Workflow.js';
import ReactJsDevelopment from './home/ReactJsDevelopment.js';
function Home(props) {
    // const [setVisible] = useState(false)
    document.title = 'Website Company in Varanasi | Website Design Company in Varanasi - TND Soft';
    document.querySelector("meta[name='description']").setAttribute('content', '"Website company in varanasi, best website design company in Varanasi, web development company in varanasi, web design and development company in varanasi, varanasi best website design company, website design in varanasi, website designing and development company in varanasi');
    document.querySelector("meta[name='keywords']").setAttribute('content', 'Best Website Company in Varanasi, We offers IT services like, website design, web development company in varanasi, web design company in Varanasi , Best website design company in Varanasi. Contact Now ');
    return (
        <React.Fragment>
            {/* banner start */}
            <HomeBanner />
            {/* banner end */}
            <ReactJsDevelopment />
            <ServicesHome setVisible={props.setVisible} scrollToTop={props.scrollToTop} />
            
            {/* about us */}
            <About title="Welcome to TND Soft" setVisible={props.setVisible} scrollToTop={props.scrollToTop} />
            {/* about us end */}
            <div className="FistServicesBx mobtext">
                <Container>
                    <div className='d-flex  flex-wrap align-items-center justify-content-between'>
                        <div className='mb-4'>
                            <Workflow setVisible={props.setVisible} scrollToTop={props.scrollToTop} iconWork="icon-server" cmsId="20" />
                        </div>
                        <div className='d-none-m'><img src={arrowone} alt="" /></div>
                        <div className='mb-4'>
                            <Workflow setVisible={props.setVisible} scrollToTop={props.scrollToTop} iconWork="icon-inbox" cmsId="21" />
                        </div>
                        <div className='d-none-m'><img src={arrowtwo} alt="" /></div>
                        <div className='mb-4'>
                            <Workflow setVisible={props.setVisible} scrollToTop={props.scrollToTop} iconWork="icon-drawers" cmsId="22" />
                        </div>
                    </div>
                </Container>
            </div>
            {/* Our Mission */}
            <OurMission title="Our Mission" />
            {/* Our Mission end */}
            {/* Clints start */}
            <Clints title="Our Clients" />
            {/* clint end */}
        </React.Fragment>

    );
}
export default Home;