import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
export default function ServicesLink(props) {
  // const [setVisible] = useState(false)
  const [items, setItems] = useState("");
  const url = "https://api.tndsoft.in/Api/getServices/";
  const fetchData = async () => {
    try {
      const response = await fetch(url);
      const json = await response.json();
      // console.log(json);
      //setContent(json.slip.advice);
      setItems(json.content);
    } catch (error) {
      // console.log("error", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // function servicedescription(v) {

  //   return { __html: v };
  // }
  // console.log(items);



  return (
    <ul>
      {
        items !== '' ? items.map((item, index) => {
          return <li key={index} ><Link as={Link} onClick={props.scrollToTop} to={`/service/${item.service_url_slug}`}>{item.title}</Link></li>;
        }) : ''
      }
    </ul>

  );
}