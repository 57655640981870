import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function erpdetails() {

    return (
        <React.Fragment>
            <div className='pageStyle'>
                <div className='pageBanner'>
                    <Container>
                        <h1>Visitor Management</h1>

                    </Container>
                </div>
                <div className='py-4'>
                    <Container>
                        <Row>
                            <Col md="8">
                                <p>Management of visitors has become an integral part for security and efficient time management and hence is an important aspect in institutions where security and information are important. Considering the wide range (Suppliers, Vendors, Publishers, Guardians) and reasons (Enquiry, Admission, Complaint, product demo, consultation, certification etc) for a visitor to visit school it is highly imperative to have a log of all such visits.
                                </p>
                                <p>The overall goal is to have a better control access thus providing enhanced protection for our staff and students.

                                </p>
                                <p>Management of visitors has become an integral part for security and efficient time management and hence is an important aspect in institutions where security and information are important. Considering the wide range (Suppliers, Vendors, Publishers, Guardians) and reasons (Enquiry, Admission, Complaint, product demo, consultation, certification etc) for a visitor to visit school it is highly imperative to have a log of all such visits.
                                </p>
                                <p>The overall goal is to have a better control access thus providing enhanced protection for our staff and students.

                                </p>
                                <p>Management of visitors has become an integral part for security and efficient time management and hence is an important aspect in institutions where security and information are important. Considering the wide range (Suppliers, Vendors, Publishers, Guardians) and reasons (Enquiry, Admission, Complaint, product demo, consultation, certification etc) for a visitor to visit school it is highly imperative to have a log of all such visits.
                                </p>
                                <p>The overall goal is to have a better control access thus providing enhanced protection for our staff and students.

                                </p>
                                <p>Management of visitors has become an integral part for security and efficient time management and hence is an important aspect in institutions where security and information are important. Considering the wide range (Suppliers, Vendors, Publishers, Guardians) and reasons (Enquiry, Admission, Complaint, product demo, consultation, certification etc) for a visitor to visit school it is highly imperative to have a log of all such visits.
                                </p>
                                <p>The overall goal is to have a better control access thus providing enhanced protection for our staff and students.

                                </p>
                                <p>Management of visitors has become an integral part for security and efficient time management and hence is an important aspect in institutions where security and information are important. Considering the wide range (Suppliers, Vendors, Publishers, Guardians) and reasons (Enquiry, Admission, Complaint, product demo, consultation, certification etc) for a visitor to visit school it is highly imperative to have a log of all such visits.
                                </p>
                                <p>The overall goal is to have a better control access thus providing enhanced protection for our staff and students.

                                </p>
                                <p>Management of visitors has become an integral part for security and efficient time management and hence is an important aspect in institutions where security and information are important. Considering the wide range (Suppliers, Vendors, Publishers, Guardians) and reasons (Enquiry, Admission, Complaint, product demo, consultation, certification etc) for a visitor to visit school it is highly imperative to have a log of all such visits.
                                </p>
                                <p>The overall goal is to have a better control access thus providing enhanced protection for our staff and students.

                                </p>
                            </Col>
                            <Col md="4">

                                <Card>
                                    <Card.Body>
                                        <div>
                                            <h5 className='border-bottom pb-1'>Administration</h5>
                                            <ul className='list-group list-group-flush'>
                                                <li className='list-group-item'><Link><i class="icon-arrow-right"></i> Gate Management</Link></li>
                                                <li className='list-group-item'><Link><i class="icon-arrow-right"></i> Complaint</Link></li>
                                                <li className='list-group-item'><Link><i class="icon-arrow-right"></i> Front Office</Link></li>
                                                <li className='list-group-item'><Link><i class="icon-arrow-right"></i> Certificate</Link></li>
                                                <li className='list-group-item'><Link><i class="icon-arrow-right"></i> Hostel</Link></li>
                                                <li className='list-group-item'><Link><i class="icon-arrow-right"></i> Store Management</Link></li>
                                                <li className='list-group-item'><Link><i class="icon-arrow-right"></i> Book Store Management</Link></li>
                                                <li className='list-group-item'><Link><i class="icon-arrow-right"></i> Time Table</Link></li>
                                            </ul>
                                        </div>

                                    </Card.Body>
                                </Card>
                                <div class="make-me-sticky mt-5">
                                    <div className='formBx'>
                                        <h4 className='text-white mb-3'>Call Back Request</h4>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="name">
                                                <Form.Control type="text" placeholder="Enter Name" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="email" placeholder="Enter email" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="mobile">
                                                <Form.Control type="text" placeholder="Enter Mobile No." />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="mobile">
                                                <Form.Select aria-label="Default select example">
                                                    <option>Open this select menu</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Button variant="primary" type="submit">
                                                Submit
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    )
}