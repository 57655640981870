import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import Spinner from './SpinnerLoading.js';
import axios from 'axios';
import Globals from '../common/Globals.js';
import ErrorPage from './ErrorPage.js';
export default function ServicesDetails(props) {
  const [showError, setpShowError] = useState(false);
  const [content, setContent] = useState("");
  const [pagename, setPagename] = useState("");
  const [pagemtitle, setPageTitle] = useState("");
  const [pageimages, setPageimages] = useState("");

  const [metatags, setMetatags] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  //console.log(url);
  useEffect(() => {
    getData(id)
  }, [id])
  const getData = async(id)=>{
    await axios.get(Globals.BASE_URL + '' + Globals.SERVICES_PAGE_URL + id, {} ,{})
    .then(function(response){
      if (response.request.status === 200) {
        // console.log(response.data)
        var count = Object.keys(response.data).length;
        if (count) {
          setPagename(response.data.service.name);
          setContent(response.data.service.detail);
          setPageimages(response.data.service.imageurl);
          setPageTitle(response.data.service.meta_title);
          setMetadescription(response.data.service.meta_description);
          setMetatags(response.data.service.meta_tags);
          setLoading(false)
        } else {
          setpShowError(true);
        }
      }else{
        setpShowError(true);
      }

    })
  }
  /*useeffectcall?fetchData():useEffect();*/
  function pagedescription() {
    return { __html: content };
  }
  // title
  document.title = pagemtitle;
  document.querySelector("meta[name='description']").setAttribute('content', metadescription);
  document.querySelector("meta[name='keywords']").setAttribute('content', metatags);
  
  // console.log(pagename)
  // title end
  return (
    <React.Fragment>
      {showError ? <ErrorPage />: loading ? (
        <div className='speen text-center'><Spinner /></div>
        ) : (
        <div className='pageStyle'>
          <div className='pageBanner'>
            <Container>
              <h1>{pagename}</h1>
            </Container>
          </div>
          <div className='pageBody'>
            <Container>
              <img src={pageimages} width="450" alt="" />
              <div dangerouslySetInnerHTML={pagedescription()}></div>
              <Link to="/checkout" onClick={props.scrollToTop} className='btn btn-warning'>Pay Now</Link>
            </Container>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
