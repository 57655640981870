import React from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import pimg from '../assets/images/GAtepass.jpg';
import { Link } from 'react-router-dom';
export default function SchoolErp() {
    return (
        <React.Fragment>
            <div className='pageStyle'>
                <div className='pageBanner'>
                    <Container>
                        <h1>School ERP / CIM</h1>
                        <p className='text-white'>Comprehensive Information Management</p>
                    </Container>
                </div>
                <Container className='py-4'>
                    <div className='SchoolErp'>
                        <Tabs
                            defaultActiveKey="home"
                            id="justify-tab-example"
                            className="mb-3 justify-content-center"
                            // justify
                            center
                            variant="pills"
                        >
                            <Tab eventKey="home" title="All">
                               <div>
                                <Row>
                                    <Col sm="3" className='mb-3'>
                                        <Link to="/erpdetails">
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                        </Link>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                    <Col sm="3" className='mb-3'>
                                        <div className='portfolioItem'>
                                            <img src={pimg} alt='' />
                                            <div className='poverlay'>
                                                <div class="ptext">Gate Management</div>
                                            </div>
                                            <h5>Gate Management</h5>
                                        </div>
                                    </Col>
                                </Row>
                               </div>
                            </Tab>
                            <Tab eventKey="profile" title="Student Information System">
                                Tab content for Profile
                            </Tab>
                            <Tab eventKey="longer-tab" title="Loooonger Tab">
                                Tab content for Loooonger Tab
                            </Tab>
                            <Tab eventKey="contact" title="Staff Information System">
                                Tab content for Contact
                            </Tab>
                            <Tab eventKey="academics" title="Academics">
                                Tab content for Contact
                            </Tab>
                            <Tab eventKey="administration" title="Administration">
                                Tab content for Contact
                            </Tab>

                            <Tab eventKey="finance" title="Finance">
                                Tab content for Contact
                            </Tab>
                            <Tab eventKey="communication" title="Communication">
                                Tab content for Contact
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </div>

        </React.Fragment >
    )
}
