import React, { useEffect, useState } from 'react';
import axios from "axios";
import Spinner from './SpinnerLoading.js';
import Globals from '../common/Globals.js';
export default function Workflow(props) {
  const [content, setContent] = useState("");
  const [pagename, setPagename] = useState("");
  // const [pageid, setPageid] = useState("");
  // const [metadescription, setMetadescription] = useState("");
  const [loading, setLoading] = useState(true);
  // console.log(props.cmsId);
  useEffect(() => {
    // console.log(props.cmsId);
    const url = Globals.BASE_URL + '' + Globals.CONTENT_PAGE_URL + '/' + props.cmsId;
    const fetchData = async () => {
      // GET request for remote image in node.js
      axios({
        method: 'get',
        url: url // GET request for remote image in node.js

      }).then(function (response) {
        // console.log("response1=", response.data);
        setPagename(response.data.pagename);
        setContent(response.data.pagecontent);
        // setMetadescription(response.data.meta_description);
        setLoading(false)
      });

      // .then(function (response) {
      //   console.log("response2=", response);
      // });
    }
    fetchData();
  }, [props.cmsId]);
  function pagedescription() {
    return { __html: content };
  }
  // title
  // document.title = pagename
  // document.getElementsByTagName("META")[3].content = metadescription;
  // document.getElementsByTagName("META")[4].content = metadescription;
  // console.log(pagename)
  // title end
  return (
    <React.Fragment>
      {loading ? (
        <div className='text-center'><Spinner /></div>
      ) : (
        <div className='sBox h-100 services-box'>
          <div className='icon'><i className={props.iconWork} ></i></div>
          <h3>{pagename}</h3>
          {/* Exceptional Services */}
          <div dangerouslySetInnerHTML={pagedescription()}></div>
          {/* <Link to="#" onClick={props.scrollToTop} className='btn btn-warning'>Read More</Link> */}
        </div>
      )}
    </React.Fragment>
  )
}