import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import Spinner from './SpinnerLoading.js';
import Globals from '../common/Globals.js';
import ErrorPage from './ErrorPage.js';
export default function PrivacyPolicy(props) {
  const [showError, setpShowError] = useState(false);
  const [content, setContent] = useState("");
  const [pagetitle, setPageTitle] = useState("");
  const [pagename, setPagename] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [metatags, setMetatags] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData()
  }, [])
  const getData = async () => {
    await axios.get(Globals.BASE_URL + '' + Globals.CONTENT_PAGE_URL + '/23', {}, {})
      .then(function (response) {
        // console.log(response)
        if (response.request.status === 200) {
          var count = Object.keys(response.data).length;
          if (count) {
            setPagename(response.data.pagename);
            setPageTitle(response.data.meta_title);
            setContent(response.data.pagecontent);
            setMetadescription(response.data.metadescription);
            setMetatags(response.data.metatags);
            setLoading(false)
          } else {
            setpShowError(true);
          }
        } else {
          setpShowError(true);
        }
      })
  }
  function pagedescription() {
    return { __html: content };
  }
  // title
  document.title = pagetitle;
  document.querySelector("meta[name='description']").setAttribute('content', metadescription);
  document.querySelector("meta[name='keywords']").setAttribute('content', metatags);
  // console.log(pagename)
  // title end
  return (
    <React.Fragment>
      {
        showError ? <ErrorPage /> :
          loading ? (
            <div className='speen text-center'><Spinner /></div>
          ) : (
            <div className='pageStyle'>
              <div className='pageBanner'>
                <Container>
                  <h1>{pagename}</h1>
                  {/* <p className='text-white'>Majestic websites add great value to your business!</p> */}
                </Container>
              </div>
              <div className='pageBody'>
                <Container>
                  <div className='text-center'></div>
                  <div dangerouslySetInnerHTML={pagedescription()}></div>
                </Container>
              </div>
            </div>
          )}
    </React.Fragment>
  );
}
