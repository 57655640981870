import React from "react"
export default function SpinnerLoading() {
    return (
        <React.Fragment>
            <div className='lodingBx'>
                {/* <Spinner animation="grow">
                    <span className="visually-hidden">Loading...</span>
                </Spinner> */}
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        </React.Fragment>
    )
}