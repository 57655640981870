import React, { useState, useEffect } from "react"
import { Container, Row, Col, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import Globals from "../common/Globals";
export default function HeaderMenu(props) {
  const [show, setShow] = useState(false);
const showDropdown = (e)=>{
    setShow(!show);
}
const hideDropdown = e => {
    setShow(false);
}
  const [items, setItems] = useState("");
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
    // menu
    const url =Globals.BASE_URL + '' + Globals.GET_Services;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        // console.log(json);
        //setContent(json.slip.advice);
        setItems(json.content);
      } catch (error) {
        // console.log("error", error);
      }
    };
    fetchData();

  }, []);
  // console.log(items);

  return (
    <React.Fragment>
      <div className='headTop d-none d-md-block'>
        <Container>
          <Row>
            <Col></Col>
            <Col md="auto">
              <ul>
                <li><i className='icon-envelope-open'></i> {props.websettings.store_email_info}</li>
                <li><i className='icon-headphones'></i> {props.websettings.store_mobile}</li>
                <li><i className='icon-phone-incoming'></i> {props.websettings.store_phone}</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="rsmenu">
        <Navbar bg="light" expand="lg" sticky="top" className={scroll ? "stickyStyle shadow" : "bg-white "}  collapseOnSelect  >
          <Container>
            <Navbar.Brand as={Link} to="./"><img src={logo} alt={props.title} /></Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-menu`} />
            <Navbar.Offcanvas 
              id={`offcanvasNavbar-menu`}
              aria-labelledby={`offcanvasNavbarLabel-menu`}
              placement="start"
              className="menuOffcanvas"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-menu`}>
                  <img src={logo} alt={props.title} style={{ height: 50 }} />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="OffcanvasMenu">
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link as={Link} eventKey="1" onClick={props.scrollToTop} to="/">Home</Nav.Link>
                  <Nav.Link as={Link} onClick={props.scrollToTop} eventKey="2" to="about-us">About</Nav.Link>
                  {/* <Nav.Link as={Link} eventKey="3"to="services">Services</Nav.Link> */}
                  <NavDropdown title="Services" id="basic-nav-dropdown"
                  show={show}
                  onMouseEnter={showDropdown} 
                  onMouseLeave={hideDropdown}
                  >
                    {
                      items !== '' ? items.map((item, index) => {
                        return <NavDropdown.Item  onClick={props.scrollToTop} key={index} href={`/service/${item.service_url_slug}`}>{item.title}</NavDropdown.Item>;
                      }) : ''
                    }
                  </NavDropdown>
                  <Nav.Link as={Link} eventKey="4" onClick={props.scrollToTop} to="portfolio">Portfolio</Nav.Link>
                  <Nav.Link as={Link} eventKey="5" onClick={props.scrollToTop} to="career">Career</Nav.Link>
                  <Nav.Link as={Link} eventKey="6" onClick={props.scrollToTop} to="school-erp">SCHOOL ERP</Nav.Link>
                  <Nav.Link as={Link} eventKey="7" onClick={props.scrollToTop} to="contact-us">Contact Us</Nav.Link>
                </Nav>
                <div className="d-md-none mt-4 border-top pt-3 menuContact">
                  <h5>Reach us</h5>
                  {/* <ul>
                    <li><i className='icon-envelope-open'></i> {props.settings.store_email_info}</li>
                    <li><i className='icon-headphones'></i> {props.settings.store_mobile}</li>
                    <li><i className='icon-phone-incoming'></i> {props.settings.store_phone}</li>
                  </ul> */}
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>

    </React.Fragment>
  );
}

