export default {
    COLOR: {
        ORANGE: '#C50',
        DARKBLUE: '#0F3274',
        LIGHTBLUE: '#6EA8DA',
        DARKGRAY: '#999',
    },
    BASE_URL: 'https://api.tndsoft.in/Api/',
    SETTING_URL: 'getWebSettings',
    GET_Services: 'getServices',
    BANNER_URL:'getBanner',
    CONTENT_PAGE_URL: 'getContent/',
    GET_CLINTS_URL: 'getClints/',
    SERVICES_PAGE_URL:'service/',
    GET_PORT_FOLIO_URL:'getPortfolio',
};

