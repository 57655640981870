import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from './SpinnerLoading.js';
import Globals from '../common/Globals.js';
import axios from 'axios';
import ErrorPage from './ErrorPage.js';
export default function Portfolio(props) {
    const [showError, setpShowError] = useState(false);
    const [flashitems, setFlashitems] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.GET_PORT_FOLIO_URL, {}, {})
            .then(function (response) {
                if (response.request.status === 200) {
                    // console.log(response);
                    var count = Object.keys(response.data).length;
                    if (count) {
                        setFlashitems(response.data.content);
                        // console.log(response.data.content);
                        setLoading(false)
                    } else {
                        setpShowError(true);
                    }
                } else {
                    setpShowError(true);
                }
            })
    }
    // console.log(flashitems);
    document.title = 'Portfolio - TND Soft'
    document.querySelector("meta[name='description']").setAttribute('content', ' tnd soft varanasi, varanasi best comoany, tnd soft portfolio');
    document.querySelector("meta[name='keywords']").setAttribute('content', 'We are work with more valuable clients. If you want to check my portfolio please click on my website portfolio tab.');
    return (
        <React.Fragment>
            {showError ? <ErrorPage />:

            loading ? (
                <div className='speen text-center'><Spinner /></div>
            ) : (
                <div className='pageStyle'>
                    <div className='pageBanner'>
                        <Container>
                            <h1>Portfolio</h1>
                        </Container>
                    </div>
                    <div className='pageBody'>
                        <Container>
                            <Row>
                                <div className="gal">
                                    {
                                        flashitems !== '' ? flashitems.map((flashit, index) => {
                                            return <Col key={index} className='shadow'>
                                                <img className="d-block w-100 pointercss" src={flashit.imageurl} alt={flashit.name} />
                                            </Col>
                                        }) : ''
                                    }

                                </div>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}

        </React.Fragment>
    );
}