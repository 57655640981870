import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import reactImg from "../../assets/images/reactBanner2.png";
export default function ReactJsDevelopment() {
  return (
    <div className="reactBoxMain">
    <Container>
        <Row className='align-items-center'>
            <Col md="8 order-md-1">
                <div className='reactStyle'>
                    <h2>React Js Development Services</h2>
                    <div className='reactInntext'>ReactJS is one of the platforms which were developed to streamline the process of front-end web application development. ReactJS is faster, simpler, adaptable and scalable.</div>
                    <div className='d-flex gap-3 reactBox mt-4'>
                        <div className='reactArrow'><i className="icon-checkmark-circle"></i></div>
                        <div>
                            <h5>Rich features and high-performance solutions </h5>
                            <p>Considering the rich features and high-performance solutions, big businesses are choosing ReactJS development as their foremost choice. Our team of best Reactjs / React developers, software engineers and programmers in India offers custom Reactjs development services that handle data updates and synchronization without page reloading, as well as integration of existing applications or systems with ReactJS.</p>
                        </div>
                    </div>
                    <div className='d-flex gap-3 reactBox mt-2'>
                        <div className='reactArrow'><i className="icon-checkmark-circle"></i></div>
                        <div>
                            <h5>Highest Success Rates</h5>
                            <p>We utilize the open source JavaScript framework of ReactJS to build different types of quick and iterative user interfaces. With ReactJS development services, our team will develop feature-rich websites aimed at streamline incoming data into organized sets for proper administration, automation and collaboration.</p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col md="4 order-md-0 d-md-block d-none">
            <img src={reactImg} className="img-fluid" alt="" />
                {/* <div>
                    HTML5 Development
                    <ProgressBar variant="success" animated now={60} />
                </div> */}
            </Col>
        </Row>
    </Container>
    <div className="bg2nd"></div>
</div>
  )
}
