import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ContactForm from './ContactForm';
import Spinner from './SpinnerLoading';
import axios from 'axios';
import Globals from '../common/Globals';
import ErrorPage from './ErrorPage';
export default function Contact(props) {
    const [showError, setpShowError] = useState(false);
    const [content, setContent] = useState("");
    const [pagetitle, setPageTitle] = useState("");
    const [pagename, setPagename] = useState("");
    const [metadescription, setMetadescription] = useState("");
    const [metatags, setMetatags] = useState("");
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        await axios.get(Globals.BASE_URL + '' + Globals.CONTENT_PAGE_URL + '/16', {}, {})
            .then(function (response) {
                // console.log(response.request.status);
                if (response.request.status === 200) {
                    var count = Object.keys(response.data).length;
                    // count=0;
                    if (count) {
                        // console.log(response.data);
                        setPagename(response.data.pagename);
                        setPageTitle(response.data.meta_title);
                        setContent(response.data.pagecontent);
                        setMetadescription(response.data.metadescription);
                        setMetatags(response.data.metatags);
                        setLoading(false)
                    }else{
                        // console.log('No Result')
                        setpShowError(true);
                    }
                } else {
                    setpShowError(true);
                }
            })
    }
    function pagedescription() {
        return { __html: content };
    }
    // title
    document.title = pagetitle;
    document.querySelector("meta[name='description']").setAttribute('content', metadescription);
    document.querySelector("meta[name='keywords']").setAttribute('content', metatags);
    // console.log(pagedescription)
    // title end
    return (
        <React.Fragment>
            {
                showError?<ErrorPage />:
                loading ? (
                    <div className='speen text-center'><Spinner /></div>
                ) : (
                    <div>
                        <div className='pageStyle'>
                            <div className='pageBanner'>
                                <Container>
                                    <h1>{pagename}</h1>
                                </Container>
                            </div>
                            <div className='pageBody'>
                                <Container>
                                    <Row>
                                        <Col md="5" className="">
                                            <div dangerouslySetInnerHTML={pagedescription()}></div>
                                        </Col>
                                        <Col md="7">
                                            <ContactForm />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28857.844637364266!2d82.944031!3d25.296456000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x154d0af7d16642cd!2sSoftware%20Company%20in%20Varanasi%20-%20TND%20Soft%20Technologies!5e0!3m2!1sen!2sin!4v1659514768734!5m2!1sen!2sin" loading="lazy" title="TND Soft" className='iframeContact' ></iframe>
                    </div>
                )}
                
             </React.Fragment>
    );
}
