// import axios from "axios";
import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [msg, setMsg] = useState('');

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [errorSubject, setErrorSubject] = useState('');
    const [errorMobile, setErrorMobile] = useState('');
    const [errorMessage, seterrorMessage] = useState('');
    

    const submitForm = (e) => {
        e.preventDefault();
        const contactobj = new FormData();
        contactobj.append('name', name);
        contactobj.append('email', email);
        contactobj.append('subject', subject);
        contactobj.append('mobile', mobile);
        contactobj.append('message', message);
        axios.post('https://api.tndsoft.in/api/contactUs', contactobj)
            // console.log({name, email, subject, mobile, message});
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.status === 'success') {
                        console.log(response);
                        setName('');
                        setEmail('');
                        setSubject('');
                        setMobile('');
                        setMessage('');
                       
                        setMsg(response.data.msg);
                    }else  if (response.data.status === 'error'){
                        setMsg(response.data.msg);
                        
                    }else  if (response.data.status === 'validation_error'){
                        setNameError(response.data.name);
                        setEmailError(response.data.email);
                        setErrorSubject(response.data.subject);
                        setErrorMobile(response.data.mobile);
                        seterrorMessage(response.data.message);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    return (
        <React.Fragment>
            <div className='contactForm'>
                <h2>Get in touch with us</h2>
                <p>Please Feel Free to Reach Out if You Have Any Questions</p>
                <p className="text-success">{msg}</p>
                <Form className='row mt-4'>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
                        <Form.Label>Your Name <span className='text-danger'>*</span></Form.Label>
                        <Form.Control value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder="" />
                        <span className="errorStyle">{nameError}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
                        <Form.Label>Email address <span className='text-danger'>*</span></Form.Label>
                        <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="" />
                        <span className="errorStyle">{emailError}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
                        <Form.Label>Subject </Form.Label>
                        <Form.Control value={subject} onChange={(e) => setSubject(e.target.value)} type="text" placeholder="" />
                        <span className="errorStyle">{errorSubject}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-6" controlId="formBasicPassword">
                        <Form.Label>Mobile no.  <span className='text-danger'>*</span></Form.Label>
                        <Form.Control value={mobile} onChange={(e) => setMobile(e.target.value)} type="text" placeholder="" />
                        <span className="errorStyle">{errorMobile}</span>
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
                        <Form.Label>Your Message <span className='text-danger'>*</span></Form.Label>
                        <Form.Control value={message} as="textarea" onChange={(e) => setMessage(e.target.value)} rows={3} type="text" placeholder="" />
                        <span className="errorStyle">{errorMessage}</span>
                   </Form.Group>
                    <div className="col-md-12 mb-3">
                        <Button variant="primary" onClick={submitForm} type="button">
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        </React.Fragment>
    )
}

export default ContactForm;