import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import axios from 'axios';
import './App.css';
import './assets/css/fonts.css';
import './assets/css/Style.scss';
import './assets/css/Responsive.scss';
import Header from './pages/HeaderMenu.js'
import Footer from './pages/Footer.js'
import Home from './pages/Home.js'
import About from './pages/About.js'
// import Page from './pages/Page.js'
import Service from './pages/ServicesDetails.js'
import Services from './pages/Services.js'
import Contact from './pages/Contact.js'
import Portfolio from './pages/Portfolio.js';
import CareerPage from './pages/CareerPage.js';
import Error from './pages/ErrorPage'
import Globals from './common/Globals';
import ReturnPolicy from './pages/ReturnPolicy.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import TermConditions from './pages/TermConditions.js';
import SchoolErp from './pages/SchoolErp.js';
import ErpDetails from './pages/ErpDetails.js';
import Checkout from './pages/Checkout.js';


function App() {
  const [visible, setVisible] = useState(false)
  const [settings, setSettings] = useState([]);
  useEffect(() => {
    getData();
  }, [])
  const getData = async () => {
    // console.log(Globals.BASE_URL + '' + Globals.SETTING_URL);
    await axios.get(Globals.BASE_URL + '' + Globals.SETTING_URL, {}, {})
      .then(function (response) {
        // console.log(response.data);
        setSettings(response.data.settings);
        // console.log(settings);
      })
  }
  // back-top
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  // back-top-end 
  return (
    <React.Fragment>
      <Header websettings={settings} setVisible={setVisible} scrollToTop={scrollToTop} title="TND Soft" />
      <Routes>
        <Route path="/" pg='/' strict={false} index element={<Home setVisible={setVisible} scrollToTop={scrollToTop} />} />
        <Route path="about-us" strict={false} pg='about-us' element={<About />} />
        <Route path="privacy-policy" strict={false} pg='privacy-policy' element={<PrivacyPolicy />} />
        <Route path="term-and-conditions" strict={false} pg='term-and-conditions' element={<TermConditions />} />
        <Route path="return-policy" strict={false} pg='return-policy' element={<ReturnPolicy />} />
        {/* <Route path="page/:id" strict={false} element={<Page />} /> */}
        <Route path="services" element={<Services />} />
        <Route path="service/:id" element={<Service />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="career" element={<CareerPage />} />
        <Route path="school-erp" element={<SchoolErp />} />
        <Route path="erpdetails" element={<ErpDetails />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="contact-us" element={<Contact settings={settings} />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer websettings={settings} scrollToTop={scrollToTop} />
      <div onClick={scrollToTop} className="backBtnStyle" style={{ display: visible ? 'inline' : 'none' }} ><i className='icon-arrow-up'></i></div>
    </React.Fragment>
  );
}
export default App;
