import React from 'react';
import { Container } from 'react-bootstrap';
import ServicesHome from './home/ServicesHome.js'

export default function Services() {
  
  // title
  document.title = 'Our Services'
  document.querySelector("meta[name='description']").setAttribute('content', '"Website company in varanasi, best website design company in Varanasi, web development company in varanasi, web design and development company in varanasi, varanasi best website design company, website design in varanasi, website designing and development company in varanasi');
    document.querySelector("meta[name='keywords']").setAttribute('content', 'Best Website Company in Varanasi, We offers IT services like, website design, web development company in varanasi, web design company in Varanasi , Best website design company in Varanasi. Contact Now ');
  // console.log(pagename)
  // title end
  return (
    <React.Fragment>
      <div className='pageStyle'>
        <div className='pageBanner'>
          <Container>
            <h1>Services</h1>
          </Container>
        </div>
        <div className='pageBody'>
        <ServicesHome  />
        </div>
      </div>
    </React.Fragment>
  );
}
