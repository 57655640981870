import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Globals from '../../common/Globals';
export default function ServicesHome(props) {
  const [items, setItems] = useState("");

  useEffect(() => {
    const url = Globals.BASE_URL + '' + Globals.GET_Services;
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        // console.log(json);
        //setContent(json.slip.advice);
        setItems(json.content);
      } catch (error) {
        // console.log("error", error);
      }
    };
    fetchData();
  }, []);
  function servicedescription(v) {

    return { __html: v };
  }
  // console.log(items);
  return (
    <React.Fragment>
      <Container>
        <div className="ServicesStyle">
          <Row>
            {
              items !== '' ? items.map((item, index) => {
                return <Col lg="6 mb-4" key={index}>
                  <div className='serviceBox h-100'>
                    <Link to={`/service/${item.service_url_slug}`}  onClick={props.scrollToTop}>
                      <Row className='align-items-center'>
                        <Col lg="3 text-center dance_hover"><i className={item.subtitle} ></i></Col>
                        <Col lg="9">
                          <h4 >{item.title}</h4>
                          <div className='detailSr'>
                            <span dangerouslySetInnerHTML={servicedescription(item.service.slice(0, 160))} ></span>
                          </div>
                        </Col>
                      </Row>
                    </Link>
                  </div>
                </Col>;
              }) : ''
            }
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
}