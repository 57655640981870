import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import Globals from '../common/Globals';
export default function Clints(props){
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        loop: false,
        center: false,
        mouseDrag: true,
        autoplay: true,
        navText: false,
        smartSpeed: 1000,

        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 2,
                dots: true,
            },
            700: {
                items: 3,
                dots: true,
            },
            1000: {
                items: 6,

            }
        },
    };

    
    const [flashitems, setFlashitems] = useState("");

    useEffect(() => {
        const url = Globals.BASE_URL + '' + Globals.GET_CLINTS_URL;
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                // console.log(json);
                //setContent(json.slip.advice);
                setFlashitems(json.content);
            } catch (error) {
                // console.log("error", error);
            }
        };
        fetchData();
    }, []);
  
    // console.log(flashitems);
    return(
        <React.Fragment>
             <div className='ClintLogo'>
                <Container>
                <h3 className='text-center mb-4'>{props.title}</h3>
                    <OwlCarousel className="slider-items owl-carousel" {...options}>
                    {
                    flashitems !== '' ? flashitems.map((flashit, index) => {
                        return <div className='item' key={index}>
                        <div className='clintbx'>
                            <img className="d-block w-100" src={flashit.imageurl} alt={flashit.name} />
                            </div>
                        </div>
                    }) : ''
                }
                       
                    </OwlCarousel>
                </Container>
            </div>
        </React.Fragment>
    );
}