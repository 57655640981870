import React from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'

export default function Checkout() {
    return (
        <React.Fragment>
            <div className='pageStyle'>
                <div className='pageBanner'>
                    <Container>
                        <h1>Checkout</h1>
                    </Container>
                </div>
                <div className='py-4'>
                    <Container>
                        <Row>
                            <Col sm="7">
                                <Card>
                                    <Card.Header className='bg-primary text-light'>Checkout form</Card.Header>
                                    <Card.Body>
                                        <Form>
                                            <Row>
                                            <div className="mb-3 col-md-12">
                                                <h5>Service</h5>
                                                <p>Website Design & Development</p>
                                            </div>
                                            <Form.Group className="mb-3 col-md-6" controlId="">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-md-6" controlId="">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="" />
                                            </Form.Group>
                                            <Form.Group className="mb-3 col-md-12" controlId="">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control  as="textarea" rows={3} placeholder="" />
                                            </Form.Group>
                                            <div className="mb-3 col-md-6">
                                                <h5>Amount: 500</h5>
                                            </div>
                                            </Row>
                                            <Button variant="primary" type="submit">
                                            Continue to Checkout
                                            </Button>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    )
}