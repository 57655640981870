import React, { useEffect, useState } from 'react';
import welcomebg from '../../assets/images/welbg.png';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Globals from '../../common/Globals';
function AboutHome(props) {
    const [content, setContent] = useState("");
    const [pagename, setPagename] = useState("");
    
    useEffect(() => {
        const url = Globals.BASE_URL + '' + Globals.CONTENT_PAGE_URL + '/1';
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                // console.log(json);
                //setContent(json.slip.advice);
                setPagename(json.pagename);
                //console.log(json.pagecontent.length);
                // length 500
                if(json.pagecontent.length>500)
                    setContent(json.pagecontent.substring(0, 500));
                else 
                    setContent(json.pagecontent)
                // length end
                /*json.pagecontent.length>250?
                 setContent(json.pagecontent.substring(0, 250)):'';*/
            } catch (error) {
                // console.log("error", error);
            }
        };
        fetchData();
    }, []);
    function pagedescription() {
        return { 
            __html: content 
        };
    }
    return (
        <React.Fragment>
            <div className="bgBox welcomeBox">
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className='text-center'>
                                <h1>{props.title}</h1>
                                <div dangerouslySetInnerHTML={pagedescription()}></div>
                                <div className="d-inline-flex gap-2">
                                    <Link to="about-us"  onClick={props.scrollToTop} className='btn btn-warning'>Read More</Link>
                                    <Link to="contact-us"  onClick={props.scrollToTop} className='btn btn-light'>Contact us</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="mt-5"><img src={welcomebg} alt={pagename} /></div>
            </div>

        </React.Fragment>
    );
}
export default AboutHome;