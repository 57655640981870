import React, { useEffect, useState } from 'react';
import mission from '../../assets/images/m.png';
import ctaShape from '../../assets/images/cta-shape.png';


import { Container, Row, Col } from 'react-bootstrap';
export default function OurMission(props) {

    const [content, setContent] = useState("");
    const [pagename, setPagename] = useState("");
   

    useEffect(() => {
        const url = "https://api.tndsoft.in/Api/getContent/17";
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                // console.log(json);
                //setContent(json.slip.advice);
                setPagename(json.pagename);
                //console.log(json.pagecontent.length);
                // length 500
                if (json.pagecontent.length > 500)
                    setContent(json.pagecontent.substring(0, 500));
                else
                    setContent(json.pagecontent)
                // length end
                /*json.pagecontent.length>250?
                 setContent(json.pagecontent.substring(0, 250)):'';*/
            } catch (error) {
                // console.log("error", error);
            }
        };
        fetchData();
    }, []);
    function pagedescription() {
        return {
            __html: content
        };
    }
    return (
        <React.Fragment>
            <div className="OurMission">
                <Container>
                    <Row>
                        <Col lg="7">
                            <div>
                                <h5>WE ARE ON</h5>
                                <h2>{props.title}</h2>
                                <div dangerouslySetInnerHTML={pagedescription()}></div>
                            </div>
                        </Col>
                        <Col lg="5"><img src={mission} className='bganimated' alt={pagename} /></Col>
                    </Row>
                </Container>
                <div className='cta-shape'><img src={ctaShape} alt="" /></div>
            </div>
        </React.Fragment>
    );
}
