import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import Globals from '../../common/Globals';
export default function HomeBanner() {
    // slider
    const [indexfls, setIndexfls] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndexfls(selectedIndex);
    };
    // slider end
    const [flashitems, setFlashitems] = useState("");

    useEffect(() => {
        const url = Globals.BASE_URL + '' + Globals.BANNER_URL;
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                // console.log(json);
                //setContent(json.slip.advice);
                setFlashitems(json.content);
            } catch (error) {
                // console.log("error", error);
            }
        };
        fetchData();
    }, []);

    // console.log(flashitems);
    return (
        <React.Fragment>
            <Carousel activeIndex={indexfls} onSelect={handleSelect} className="BannerBox">
                {
                    flashitems !== '' ? flashitems.map((flashit, index) => {
                        return <Carousel.Item key={index}>
                            <img className="d-block w-100" src={flashit.imageurl} alt={flashit.name} />
                        </Carousel.Item>
                    }) : ''
                }
            </Carousel>
        </React.Fragment>
    );
}