import React from 'react';
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
export default function ErrorPage() {
    return (
        <React.Fragment>
            <div className='pageStyle'>
                <div className='pageBanner'>
                    <Container>
                        <h1>404 error</h1>
                    </Container>
                </div>
                <div className='pageBody'>
                    <Container>
                        <div className='text-center'>
                            <h1>Oops! <br /> 404 Not Found</h1>
                            <p>Sorry, an error has occured, Requested page not found!</p>
                            <Link  className="btn btn-outline-primary" to='./'>Back to Home </Link>
                        </div>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    )
}